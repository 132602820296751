import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GlossaryDialogComponent } from '../../shared/components/glossary-dialog/glossary-dialog.component';

const WIRED_LINK_ATTRIBUTE = '_ngx_markdown_rewired_link';
export const GLOSSARY_IDENTIFIER = '/glossar?term=';

/**
 * The issue with custom rendering angular specific components/directive persists in ngx-markdown module.
 * For now this is the workaround to open dialogs on glossary entries.
 * The markdown libs github issues reference this problem, maybe one day it will be solved:
 https://github.com/jfcere/ngx-markdown/issues/125
 */
@Injectable({ providedIn: 'root' })
export class LinkHandlerService {
  constructor(private router: Router, private dialog: MatDialog) {
  }

  private linkShouldBeWired(link: HTMLAnchorElement): boolean {
    const attributeNames = link.getAttributeNames();

    const isAngularLink = attributeNames.some(n => n.startsWith('_ngcontent'));
    const isAlreadyWired = attributeNames.some(n => n === WIRED_LINK_ATTRIBUTE);

    return !isAngularLink && !isAlreadyWired && link.getAttribute('href').startsWith('/');
  }

  public wireInsertedLinks(element: ElementRef): void {
    if (element) {
      const allLinks: HTMLAnchorElement[] = Array.from(element.nativeElement.getElementsByTagName('a'));
      allLinks.filter(this.linkShouldBeWired).forEach(link => {
        link.addEventListener('click', $event => {
          $event.preventDefault();
          const routeTo = link.getAttribute('href');
          if (routeTo.includes('/glossar?term=')) {
            const name = routeTo.split(GLOSSARY_IDENTIFIER).pop();
            this.dialog.open(GlossaryDialogComponent, {
              width: '300px',
              data: {
                name: decodeURI(name)
              }
            });
          } else {
            this.router.navigateByUrl(routeTo);
          }
        });

        link.setAttribute(WIRED_LINK_ATTRIBUTE, '');
      });
    }
  }
}
