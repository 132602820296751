[
  {
    "from": " ",
    "to": "-"
  },
  {
    "from": "/",
    "to": "-"
  },
  {
    "from": "%",
    "to": "-"
  },
  {
    "from": "&",
    "to": "-"
  },
  {
    "from": "ü",
    "to": "ue"
  },
  {
    "from": "ö",
    "to": "oe"
  },
  {
    "from": "ä",
    "to": "ae"
  },
  {
    "from": "Ü",
    "to": "Ue"
  },
  {
    "from": "Ö",
    "to": "Oe"
  },
  {
    "from": "Ä",
    "to": "Ae"
  },
  {
    "from": "ß",
    "to": "ss"
  },
  {
    "from": "é",
    "to": "e"
  },
  {
    "from": "è",
    "to": "e"
  },
  {
    "from": "ê",
    "to": "e"
  },
  {
    "from": "à",
    "to": "a"
  },
  {
    "from": "ñ",
    "to": "n"
  },
  {
    "from": "?",
    "to": ""
  },
  {
    "from": "(",
    "to": ""
  },
  {
    "from": ")",
    "to": ""
  }
]
