import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum LegendDialogModes {
  besprechung,
  ytbesprechung,
}

@Component({
  selector: 'app-legend-dialog',
  templateUrl: './legend-dialog.component.html',
  styleUrls: ['./legend-dialog.component.scss'],
  standalone: false,
})
export class LegendDialogComponent {
  ldm = LegendDialogModes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LegendDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
