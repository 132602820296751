import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { CustomSelectOptionComponent } from '../custom-select-option/custom-select-option.component';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import difference from 'lodash-es/difference';
import union from 'lodash-es/union';

export interface CustomSelectDialogConfig {
  name: string;
  options: CustomSelectOptionComponent[];
  translatePrefix: string;
  selection: CustomSelectOptionComponent[];
  assets: { key: string, imgPath: string, iconClass: string, iconName: string }[];
  minHeight: number | null;
  mode: 'normal' | 'customSrc';

}

@Component({
    selector: 'app-custom-select-dialog',
    templateUrl: './custom-select-dialog.component.html',
    styleUrls: ['./custom-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomSelectDialogComponent implements OnInit {
  private selection: CustomSelectOptionComponent[];

  @ViewChild('content') content: ElementRef;
  private touchstart: any;
  itemgaps = '12px'; // Accepts the same as css or fxLayoutGap property

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomSelectDialogConfig, private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CustomSelectDialogComponent>) {
  }

  get streamingOptions(): any[] {
    return this.data.options.filter(value => value.group === 'streaming');
  }

  get tvOptions(): any[] {
    return this.data.options.filter(value => value.group === 'tv');
  }

  get kinoOptions(): any[] {
    return this.data.options.filter(value => value.group === 'kino');
  }

  get socialOptions(): any[] {
    return this.data.options.filter(value => value.group === 'social');
  }


  ngOnInit(): void {
    this.selection = [...this.data.selection];
  }

  close(): void {
    this.dialogRef.close();
  }

  search(): void {
    this.dialogRef.close({ search: true, selection: this.selection });
  }

  selectFilter(o): void {
    if (this.selection && this.selection.length > 0) {
      const itemIdx = this.selection.findIndex(value => value === o);
      if (itemIdx > -1) {
        this.selection.splice(itemIdx, 1);
      } else {
        this.selection.push(o);
      }
    } else {
      this.selection.push(o);
    }
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  inSelection(o: CustomSelectOptionComponent): boolean {
    return this.selection.findIndex(value => value.key === o.key) > -1;
  }

  hasIcon(o: CustomSelectOptionComponent): boolean {
    return this.data && this.data.assets && this.data.assets.findIndex(value => value.key === o.key && value.iconName) > -1;
  }

  hasImg(o: CustomSelectOptionComponent): boolean {
    return this.data && this.data.assets && this.data.assets.findIndex(value => value.key === o.key && value.imgPath) > -1;
  }

  getAssetConfig(o: CustomSelectOptionComponent): any {
    return this.data.assets.find(value => value.key === o.key);
  }

  resetClose(): void {
    this.dialogRef.close({ search: true, selection: [] });
  }

  selectAll(type: string): void {
    if (type === 'streaming') {
      const sortedForm = sortBy(filter(this.selection, (v) => {
        return v.key !== 'joyn';
      }));

      if (difference(sortBy(this.streamingOptions), sortedForm).length === 0) {
        this.selection = this.selection.filter(
          function (e) {
            return this.indexOf(e) < 0;
          },
          this.streamingOptions
        );
      } else {
        this.selection = union([...this.selection ?? []], [...this.streamingOptions]);
      }

    }
    if (type === 'tv') {
      if (difference(sortBy(this.tvOptions), sortBy(this.selection)).length === 0) {
        this.selection = this.selection.filter(
          function (e) {
            return this.indexOf(e) < 0;
          },
          this.tvOptions
        );
      } else {
        this.selection = union([...this.selection ?? []], [...this.tvOptions]);
      }
    }

    if (type === 'kino') {
      if (difference(sortBy(this.kinoOptions), sortBy(this.selection)).length === 0) {
        this.selection = this.selection.filter(
          function (e) {
            return this.indexOf(e) < 0;
          },
          this.kinoOptions
        );
      } else {
        this.selection = union([...this.selection ?? []], [...this.kinoOptions]);
      }
    }

    if (type === 'social') {
      if (difference(sortBy(this.socialOptions), sortBy(this.selection)).length === 0) {
        this.selection = this.selection.filter(
          function (e) {
            return this.indexOf(e) < 0;
          },
          this.socialOptions
        );
      } else {
        this.selection = union([...this.selection ?? []], [...this.socialOptions]);
      }
    }

    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  handleScrollMove($event: any): void {
    const el = this.content.nativeElement;
    const te = $event.changedTouches[0].clientY;
    if (el.scrollTop === 0) {
      if (this.touchstart > te) {
      } else {
        $event.preventDefault();
      }
    } else if (el.clientHeight <= el.scrollTop)
      if (this.touchstart > te && $event.cancelable) {
        $event.preventDefault();
      } else {
      }
  }

  handleScrollStart($event: any): void {
    this.touchstart = $event.touches[0].clientY;
  }
}
