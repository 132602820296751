import Url from 'url-parse';
import { format } from 'date-fns';

/**
 * Easy deep copy for NON-RECURSIVE objects
 * @param obj
 */
export function deepCopy(obj): any {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Parse the channel uri to get the channelId for youtube API Calls
 * @param channelUri
 */
export function getYoutubeIdFromChannelUri(channelUri: string): string {
  return channelUri ? new Url(channelUri).pathname.split('/').pop() : undefined;
}

export function parseDate(dateAsString): Date {
  return new Date(dateAsString);
}

export function formatDate(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

/**
 * Performance wise best solution for absolue link test
 * @param link
 */
export function isAbsoluteLinkFast(link: string): boolean {
  return link.indexOf('http://') === 0 || link.indexOf('https://') === 0;
}

/**
 * For complex links we need to use this? Not sure if needed
 * @param link
 */
export function isAbsoluteLinkFull(link: string): boolean {
  const tester = new RegExp('^(?:[a-z]+:)?//', 'i');
  return tester.test(link);
}
