<div></div>
<span class="d-flex justify-content-between mat-subtitle-2">
  <span> {{data.title}}</span>
  <mat-icon (click)="close()">cancel</mat-icon>
</span>
<div mat-dialog-content fxLayout="column">
  <span>{{data.text}}</span>
</div>
<div *ngIf="data.buttonText" mat-dialog-actions>
  <button class="flimmo-outline-button f-button-label mt-1 outline-none clickable" mat-dialog-close>
    {{data.buttonText | uppercase}}</button>
</div>
