<span fxLayout="column" class="position-relative">
  <span mat-dialog-title class="d-flex justify-content-between mat-headline-2 margin-override gap">
    <span> {{data.name | titlecase}}</span>
    <mat-icon class="clickable" (click)="close()">cancel</mat-icon>
  </span>
  <div *ngIf="data && data.options" class="content" [ngStyle]="{'min-height.px': data.minHeight || null}"
    [ngClass]="{'gap': data.mode !== 'customSrc'}" #content (touchstart)="handleScrollStart($event)"
    (touchmove)="handleScrollMove($event)" mat-dialog-content>
    <span fxLayout="column">
      <span *ngIf="data.mode === 'normal'" fxLayout="column" fxFlex="90" [fxLayoutGap]="itemgaps">
        <span *ngFor="let o of data?.options" fxLayout="row" fxLayoutAlign="space-between" class="item"
          (click)="selectFilter(o) || $event.stopPropagation()">
          <mat-checkbox [disableRipple]="true" class="cb" color="primary" [checked]="inSelection(o)" class="w-100"
            >
            <span class="cb">{{!data?.translatePrefix ? o.value : data?.translatePrefix + o.key | translate}}</span>
          </mat-checkbox>
          <mat-icon *ngIf="hasIcon(o)" [ngClass]="getAssetConfig(o).iconClass">{{getAssetConfig(o).iconName}}</mat-icon>
          <img *ngIf="hasImg(o)" [src]="getAssetConfig(o).imgPath" height="24" width="24">
        </span>
      </span>
      <!--      This is a custom implementation for tv source option groups, generic option group support needs to be implemented if/when we need options groups again-->
      <span *ngIf="data.mode === 'customSrc'" fxLayout="column" fxFlex="90" [fxLayoutGap]="itemgaps">
        <span fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="left" class="item clickable mat-headline-1  select-titles"
          (click)="selectAll('streaming')">
          <span fxLayout="row" #title fxLayoutAlign="left center" class="primary-color">{{'Streaming'}}</span>
          <span fxLayout="row" [ngStyle]="{'height.px': title.clientHeight}" class="f-hint-link align-items-center">
            (<span fxLayout="row" fxLayoutAlign="left center" class="text-decoration-underline ">alle</span>)
          </span>
        </span>

        <span *ngFor="let o of streamingOptions" fxLayout="row" class="item" fxLayoutAlign="space-between"
          (click)="selectFilter(o) || $event.stopPropagation()">
          <mat-checkbox [disableRipple]="true" class="cb" color="primary" [checked]="inSelection(o)" class="w-100"
            >
            <span class="cb">{{!data?.translatePrefix ? o.value : data?.translatePrefix + o.key | translate}}</span>
          </mat-checkbox>
          <mat-icon *ngIf="hasIcon(o)" [ngClass]="getAssetConfig(o).iconClass">{{getAssetConfig(o).iconName}}</mat-icon>
        </span>

        <span fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="left center"
          class="itemTitle clickable mat-headline-1 select-titles" (click)="selectAll('social')">
          <span fxLayout="row" fxLayoutAlign="left center" class="primary-color">{{'Social Media'}}</span>
          <span fxLayout="row" [ngStyle]="{'height.px': title.clientHeight}" class="f-hint-link align-items-center">
            (<span fxLayout="row" fxLayoutAlign="left center" class="text-decoration-underline ">alle</span>)
          </span>
        </span>

        <span *ngFor="let o of socialOptions" fxLayout="row" class="item" fxLayoutAlign="space-between"
          (click)="selectFilter(o) || $event.stopPropagation()">
          <mat-checkbox [disableRipple]="true" class="cb w-100" color="primary" [checked]="inSelection(o)" >
            <span>{{!data?.translatePrefix ? o.value : data?.translatePrefix + o.key | translate}}</span>
          </mat-checkbox>
          <mat-icon *ngIf="hasIcon(o)" [ngClass]="getAssetConfig(o).iconClass">{{getAssetConfig(o).iconName}}</mat-icon>
        </span>



        <span fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="left center"
          class="itemTitle clickable mat-headline-1 select-titles" (click)="selectAll('tv')">
          <span fxLayout="row" fxLayoutAlign="left center" class="primary-color">{{'TV'}}</span>
          <span fxLayout="row" [ngStyle]="{'height.px': title.clientHeight}" class="f-hint-link align-items-center">
            (<span fxLayout="row" fxLayoutAlign="left center" class="text-decoration-underline ">alle</span>)
          </span>
        </span>

        <span *ngFor="let o of tvOptions" fxLayout="row" class="item" fxLayoutAlign="space-between"
          (click)="selectFilter(o) || $event.stopPropagation()">
          <mat-checkbox [disableRipple]="true" class="cb w-100" color="primary" [checked]="inSelection(o)" >
            <span>{{!data?.translatePrefix ? o.value : data?.translatePrefix + o.key | translate}}</span>
          </mat-checkbox>
          <mat-icon *ngIf="hasIcon(o)" [ngClass]="getAssetConfig(o).iconClass">{{getAssetConfig(o).iconName}}</mat-icon>
        </span>

        <span fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="left center"
          class="itemTitle clickable mat-headline-1 primary-color select-titles" (click)="selectAll('kino')">
          <span fxLayout="row" fxLayoutAlign="left center">{{'Kino'}}</span>
          <span fxLayout="row" [ngStyle]="{'height.px': title.clientHeight}" class="f-hint-link align-items-center">
          </span>
        </span>

        <span *ngFor="let o of kinoOptions" fxLayout="row" class="item" fxLayoutAlign="space-between"
          (click)="selectFilter(o) || $event.stopPropagation()">
          <mat-checkbox [disableRipple]="true" class="cb w-100" color="primary" [checked]="inSelection(o)" >
            <span>{{!data?.translatePrefix ? o.value : data?.translatePrefix + o.key | translate}}</span>
          </mat-checkbox>
          <mat-icon *ngIf="hasIcon(o)" [ngClass]="getAssetConfig(o).iconClass">{{getAssetConfig(o).iconName}}</mat-icon>
        </span>

        <span fxLayout="row" class="empty-item"></span>
      </span>
    </span>
  </div>
  <span *ngIf="data.mode === 'customSrc'" class="scroll-fade"></span>
  <div mat-dialog-actions fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="15px" fxLayoutGap="20px"
    class="button-container">
    <button fxLayout="row" fxLayoutAlign="center center"
      class="flimmo-outline-button action-button f-button-label outline-none clickable" (click)="search()">
      {{'optionsDialog.button1' | translate}}
    </button>
    <button fxLayout="row" fxLayoutAlign="center center"
      class="flimmo-outline-button action-button f-button-label outline-none clickable" (click)="resetClose()">
      {{'optionsDialog.button2' | translate}}
    </button>
  </div>
</span>
