/**
 * Flimmo API V.2
 * Flimmo API V.2 ist der Nachfolger der XML-Datenschnitstelle
 *
 * The version of the OpenAPI document: 2.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Glossary } from '../model/models';
import { SearchResponse } from '../model/models';
import { ShortUrlMap } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    DefaultServiceInterface
} from './default.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class DefaultService implements DefaultServiceInterface {

    protected basePath = 'https://ddn001.flimmo.de/api/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get an alias entry, ie a shortUrl name mapping to a url path (eg \&#39;besprechung/1234\&#39;)
     * @param name shortUrl name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAlias(name: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ShortUrlMap>;
    public getAlias(name: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ShortUrlMap>>;
    public getAlias(name: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ShortUrlMap>>;
    public getAlias(name: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getAlias.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ShortUrlMap>(`${this.configuration.basePath}/alias/${encodeURIComponent(String(name))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a glossary entry, ie a definition text for a term
     * @param term urlencoded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGlossary(term: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Glossary>;
    public getGlossary(term: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Glossary>>;
    public getGlossary(term: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Glossary>>;
    public getGlossary(term: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (term === null || term === undefined) {
            throw new Error('Required parameter term was null or undefined when calling getGlossary.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Glossary>(`${this.configuration.basePath}/glossar`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fulltext queries in title of besprechung
     * q querystring, mode 0 full word match or mode 1 prefix match, altersfreigabe 3,5,7,9,11 , altersgruppen 0,1 , rating 1,2,3,4 (the openapi angular generator needed the &#x60;exploded: false&#x60;, despite being the default)
     * @param rating rating for shows 1,2,3,4
     * @param altersgruppen fuer mehrere Altersgruppen geeignet 0&#x3D;false,1&#x3D;true,
     * @param altersfreigabe 3,5,7,9,11
     * @param mode 0 for words, 1 for prefix matching
     * @param q search string
     * @param offset paging offset - which page
     * @param limit paging limit - nr of records
     * @param src List of defined src of streamers, mediatheken, tv channels, youtube, kino. Use &#x60;any&#x60; so get results with any src connected.
     * @param genre genres to show
     * @param keyword keyword ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(rating?: Array<number>, altersgruppen?: number, altersfreigabe?: Array<number>, mode?: number, q?: string, offset?: number, limit?: number, src?: Array<string>, genre?: Array<string>, keyword?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SearchResponse>;
    public search(rating?: Array<number>, altersgruppen?: number, altersfreigabe?: Array<number>, mode?: number, q?: string, offset?: number, limit?: number, src?: Array<string>, genre?: Array<string>, keyword?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SearchResponse>>;
    public search(rating?: Array<number>, altersgruppen?: number, altersfreigabe?: Array<number>, mode?: number, q?: string, offset?: number, limit?: number, src?: Array<string>, genre?: Array<string>, keyword?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SearchResponse>>;
    public search(rating?: Array<number>, altersgruppen?: number, altersfreigabe?: Array<number>, mode?: number, q?: string, offset?: number, limit?: number, src?: Array<string>, genre?: Array<string>, keyword?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (rating) {
            queryParameters = this.addToHttpParams(queryParameters,
                rating.join(COLLECTION_FORMATS['csv']), 'rating');
        }
        if (altersgruppen !== undefined && altersgruppen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>altersgruppen, 'altersgruppen');
        }
        if (altersfreigabe) {
            queryParameters = this.addToHttpParams(queryParameters,
                altersfreigabe.join(COLLECTION_FORMATS['csv']), 'altersfreigabe');
        }
        if (mode !== undefined && mode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mode, 'mode');
        }
        if (q !== undefined && q !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>q, 'q');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (src) {
            queryParameters = this.addToHttpParams(queryParameters,
                src.join(COLLECTION_FORMATS['csv']), 'src');
        }
        if (genre) {
            queryParameters = this.addToHttpParams(queryParameters,
                genre.join(COLLECTION_FORMATS['csv']), 'genre');
        }
        if (keyword) {
            queryParameters = this.addToHttpParams(queryParameters,
                keyword.join(COLLECTION_FORMATS['csv']), 'keyword');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SearchResponse>(`${this.configuration.basePath}/search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
