<span mat-dialog-title class="d-flex justify-content-between m-0 titlepadding">
  <span class="mat-subtitle-2 font-weight-bold" fxLayout="column"></span>
  <mat-icon (click)="close()" class="clickable">cancel</mat-icon>
</span>
<span mat-dialog-content fxLayout="column" class="mat-body" fxLayoutGap="10px" fxLayoutGap.xs="24px">
  <span class="font-weight-bold section-title">{{'legendDialog.section1.title' | translate}}</span>
  <span fxLayout="row" fxLayoutGap="16px" class="item">
    <div fxFlex="24px">
      <mat-icon class="mat-success">circle</mat-icon>
    </div>
    <span>{{'legendDialog.section1.item1' | translate}}</span>
  </span>
  <span fxLayout="row" fxLayoutGap="16px" fxFlex class="item">
    <div fxFlex="24px">
      <mat-icon class="mat-warning">circle</mat-icon>
    </div>
    <span>{{'legendDialog.section1.item2' | translate}}</span>
  </span>
  <span fxLayout="row" fxLayoutGap="16px" fxFlex class="item">
    <div fxFlex="24px">
      <mat-icon class="mat-danger">circle</mat-icon>
    </div>
    <span>{{'legendDialog.section1.item3' | translate}}</span>
  </span>

  <span *ngIf="data.mode === ldm.besprechung" class="font-weight-bold"
    fxFlexOffset="14px">{{'legendDialog.section2.title' | translate}}</span>
  <span *ngIf="data.mode === ldm.besprechung" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center" class="item">
    <img src="assets/media/rating_badge.svg" [alt]="'searchList.selectors.ratingOptions.4' | translate" height="24"
      width="24">
    <span>{{'legendDialog.section2.item1' | translate}}</span>
  </span>

  <span fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="12px" class="item">
    <a tabindex="-1" class="flimmo-outline-button d-flex align-items-center f-button-label outline-none clickable" [routerLink]="['/redtext/101234']"
      mat-dialog-close>
      {{'legendDialog.button' | translate | uppercase}}</a>
  </span>
  <br>
</span>
