<div class="input-wrapper">
  <div class="input" [class.required]="required" #dropreference>
    <label class="label-text">{{label}}</label>
    <input #input placeholder={{placeholder}} [disabled]="disabled" readonly
      [ngClass]="{'hasSelection': selected && selected?.length > 0  && selected[0] != 'any'}"
      [value]="showSelected ? displayText : ''" autocomplete="off" (keydown)="onKeyDown($event)" (blur)="onTouched()"
      (click)="openDialog()">
    <span class="arrow-down" (click)="openDialog()"></span>
    <app-custom-dropdown [reference]="dropreference" #dropdownComp>
      <div class="dropdown-options-container">
        <ng-content></ng-content>
      </div>
    </app-custom-dropdown>
  </div>
</div>
