<span class="d-flex justify-content-between mat-subtitle-2 dialog-title">
  <span *ngIf="data.srcName != null">{{data.srcName}}</span>
  <span *ngIf="this.data.mode === mdMode.youtube">
    {{'ytb.'+data.src | translate}}
  </span>
  <mat-icon class="clickable" (click)="close()">cancel</mat-icon>
</span>
<div mat-dialog-content
  class="dialog-content"
  *ngIf="data && this.media && this.media.length > 0 || this.data.mode === mdMode.youtube || this.data.sourceType === BesprechungSourceTypes[BesprechungSourceTypes.streaming]">
  <span [ngSwitch]="data.sourceType">

    <span
      *ngSwitchCase="BesprechungSourceTypes[BesprechungSourceTypes.mediathek] || BesprechungSourceTypes[BesprechungSourceTypes.streaming]">
      <span *ngIf="!goodRating()">{{'besprechung.redYellowWarning' | translate}}</span>
      <span *ngIf="goodRating()">
        <span class="warning d-flex">{{'besprechung.linkWarning' | translate}}</span>
        <span fxLayout="row" fxLayoutAlign="start center" class="item clickable" fxLayoutGap="10px"
          (click)="handleSubmit(r.link)" *ngFor="let r of getDistinctResources(), trackBy: trackDistinctResources">
          <span [id]="r.link" fxFlex="20" (click)="handleSubmit(r.link)" class="d-flex">
            <mat-icon>play_circle_filled</mat-icon>
          </span>
          <span fxFlex>{{r.title}}</span>
        </span>
      </span>
    </span>

    <span *ngSwitchCase="BesprechungSourceTypes[BesprechungSourceTypes.streaming]">
      <span *ngIf="!goodRating()">{{'besprechung.redYellowWarning' | translate}}</span>
      <span *ngIf="goodRating()">{{'besprechung.linkWarning' | translate}}</span>
    </span>

    <span *ngSwitchCase="BesprechungSourceTypes[BesprechungSourceTypes.tv]">
      <span *ngFor="let d of daysForTv" fxLayout="row" class="item" fxLayoutGap="10px" fxLayoutAlign="space-between">

        <span fxLayout="column">
          <span class="mb1"><span class="font-weight-bold">{{'days.' + d.day | translate}}</span>,
            {{dayFormat(d.date)}}</span>
        </span>
        <span fxLayout="column" fxLayoutGap="3px">
          <span *ngFor="let s of getShowsOnDay(d.date)" fxLayout="row" class="mb1">
            <span>{{getStartTimeOnAirdate(s)}} - {{getEndTimeOnAirdate(s)}}</span>
          </span>
        </span>

      </span>
    </span>

    <span *ngSwitchDefault></span>

  </span>

</div>
<div mat-dialog-actions *ngIf="actionsVisible() || (data.mode === mdMode.youtube && goodRating())" fxLayout="row"
  fxLayoutAlign="center" class="d-flex actions">
  <button class="flimmo-outline-button f-button-label mt-1 outline-none" (click)="handleSubmit()">
    {{'besprechung.toService' | translate | uppercase}}</button>
</div>
