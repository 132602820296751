import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChipsScrollableComponent } from './components/chips-scrollable/chips-scrollable.component';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { TrafficLightsComponent } from './components/traffic-lights/traffic-lights.component';
import { AgeCategoryBadgeComponent } from './components/age-category-badge/age-category-badge.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SwimlanesComponent } from './components/swimlanes/swimlanes.component';
import { SafePipe } from 'safe-pipe';
import { GlossaryDialogComponent } from './components/glossary-dialog/glossary-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MediaDialogComponent } from './components/media-dialog/media-dialog.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { LegendDialogComponent } from './components/legend-dialog/legend-dialog.component';
import { NoteDialogComponent } from './components/note-dialog/note-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomSelectOptionComponent } from './components/custom-select/custom-select-option/custom-select-option.component';
import { CustomDropdownComponent } from './components/custom-select/custom-dropdown/custom-dropdown.component';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomSelectDialogComponent } from './components/custom-select/custom-select-dialog/custom-select-dialog.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker/scroll-tracker.directive';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { MarkdownModule } from 'ngx-markdown';


@NgModule({
  declarations: [ChipsScrollableComponent,
    TrafficLightsComponent,
    AgeCategoryBadgeComponent,
    SwimlanesComponent,
    GlossaryDialogComponent,
    MediaDialogComponent,
    LegendDialogComponent,
    NoteDialogComponent,
    CustomSelectComponent,
    CustomSelectOptionComponent,
    CustomDropdownComponent,
    CustomSelectDialogComponent,
    NoteDialogComponent,
    ScrollTrackerDirective],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    SafePipe,
    MatIconModule,
    MatDialogModule,
    OverlayModule,
    MatCheckboxModule,
    PortalModule,
    FormsModule,
    ReactiveFormsModule,
    TruncateModule,
    MarkdownModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    SafePipe,
    ChipsScrollableComponent,
    TrafficLightsComponent,
    AgeCategoryBadgeComponent,
    SwimlanesComponent,
    GlossaryDialogComponent,
    MediaDialogComponent,
    NoteDialogComponent,
    ScrollTrackerDirective,
    NoteDialogComponent,
    CustomSelectComponent,
    CustomSelectOptionComponent,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class SharedModule {
}
