import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-age-category-badge',
    templateUrl: './age-category-badge.component.html',
    styleUrls: ['./age-category-badge.component.scss'],
    standalone: false
})
export class AgeCategoryBadgeComponent {
  getAsset = AgeCategoryBadgeComponent.getImageAssetByAgeCategory;
  getAlt = AgeCategoryBadgeComponent.getImageAssetAltTextTranslationStringByAgeCategory;


  @Input() ageCategory;
  @Input() height = 58;
  @Input() width = 58;

  static getImageAssetByAgeCategory(ageCategory): string {
    switch (ageCategory) {
      case 3:
        return 'assets/media/age_category_3.svg';
      case 5:
        return 'assets/media/age_category_5.svg';
      case 7:
        return 'assets/media/age_category_7.svg';
      case 9:
        return 'assets/media/age_category_9.svg';
      case 11:
        return 'assets/media/age_category_11.svg';
    }
  }

  static getImageAssetAltTextTranslationStringByAgeCategory(ageCategory): string {
    switch (ageCategory) {
      case 3:
        return 'searchList.selectors.ageOptionsAlt.3';
      case 5:
        return 'searchList.selectors.ageOptionsAlt.5';
      case 7:
        return 'searchList.selectors.ageOptionsAlt.7';
      case 9:
        return 'searchList.selectors.ageOptionsAlt.9';
      case 11:
        return 'searchList.selectors.ageOptionsAlt.11';
    }
  }
}
