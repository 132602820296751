import {AfterViewInit, Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';

import {Location} from '@angular/common';
import {ScrollTrackerService} from './scroll-tracker.service';
import {filter} from 'rxjs/operators';
import {SubSink} from 'subsink';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[scrollTracker]',
    standalone: false
})
export class ScrollTrackerDirective implements AfterViewInit, OnChanges, OnDestroy {
  private element: HTMLElement;
  private subs = new SubSink();
  private url: string;
  @Input() public input: any;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private scrollTrackerService: ScrollTrackerService,
    private zone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.setScrollPosition();

    /**
     * Listen for when the component's route is exited.
     * Store the current scroll position in the service.
     */
    this.subs.sink = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      // observeOn(asyncScheduler)
    )
      .subscribe(e => {
        if (this.element.scrollLeft > 0) {
          this.scrollTrackerService.saveScroll(this.url, {
            elementId: this.element.id || null,
            position: this.element.scrollLeft
          });
        }
      });
  }

  private setScrollPosition() {
    this.element = this.elementRef.nativeElement;
    this.url = this.location.path();
    const scroll = this.scrollTrackerService.getScroll(this.url);
    let element = null;
    if (scroll) {
      element = scroll.find(value => value.elementId === this.element.id);
      if (element) {
        if (this.element.scrollWidth >= element.position) {
          this.element.scrollLeft = element.position;
          this.scrollTrackerService.signalParentToUpdate();
        }
      }
    } else {
      this.element.scrollLeft = 0;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input) {
      this.setScrollPosition();
    }
  }

}
