import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { format, isAfter, isBefore } from 'date-fns';
import { BesprechungService, Medien } from '../../../generated-api';
import { BesprechungChannelTypes } from '../../../core/helpers/models/besprechungChannelTypes';
import { parseDate } from '../../functions/utils';

interface MediaDialogConfig {
  besprechungId: number;
  src: string;
  srcName: string;
  sourceType: string;
  mode: MediaDialogMode;
  url: string;
  rating: number;
}

export enum MediaDialogMode {
  media,
  youtube
}

@Component({
    selector: 'app-media-dialog',
    templateUrl: './media-dialog.component.html',
    styleUrls: ['./media-dialog.component.scss'],
    standalone: false
})
export class MediaDialogComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  media: Medien[];
  BesprechungSourceTypes = BesprechungChannelTypes;
  daysForTv: any[];
  mdMode = MediaDialogMode;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MediaDialogConfig, private besprechungService: BesprechungService,
    public dialogRef: MatDialogRef<MediaDialogComponent>) {
  }

  ngOnInit(): void {
    if (this.data.mode === MediaDialogMode.media) {
      this.subs.sink = this.besprechungService.getMediaList(this.data.besprechungId, this.data.src)
        .subscribe(value => {
          this.media = value.medienliste;
          if (this.data.sourceType === BesprechungChannelTypes[BesprechungChannelTypes.tv]) {
            this.daysForTv = this.media.map(value1 => {
              return {
                day: new Date(parseDate(value1.airdate)).getDay(),
                date: new Date(parseDate(value1.airdate))
              };
            });
            if (this.daysForTv.length > 0) {
              let timeInterval = 0;
              let daysForTvFound = [];
              while (daysForTvFound.length === 0 && this.hasShowAfterToday(this.daysForTv)) {
                timeInterval = timeInterval + 7;
                const startOfToday = new Date(new Date().setHours(0, 0, 0));
                const endOfWeek = new Date().setDate(new Date().getDate() + timeInterval);
                daysForTvFound = this.daysForTv
                  .filter(value1 => {
                    return isAfter(value1.date, startOfToday) && isBefore(value1.date, endOfWeek);
                  })
                  .filter((value1, index, self) => self.findIndex(value2 => value1.day === value2.day) === index);
              }
              this.daysForTv = daysForTvFound;
            }
          }
        });
    }

  }

  private hasShowAfterToday(daysForTv: any[]): boolean {
    const startOfToday = new Date(new Date().setHours(0, 0, 0));
    return this.daysForTv.some(value => isAfter(value.date, startOfToday));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  trackDistinctResources(index, dr): string {
    return dr.link;
  }

  handleSubmit(mediaurl?: string): void {
    if (this.data.mode === MediaDialogMode.media) {
      switch (this.data.sourceType) {
        case BesprechungChannelTypes[BesprechungChannelTypes.tv]:
          window.open(location.origin + '/exit?url=' + this.media[0].mediaurl, "_blank");
          this.close();
          break;
        case BesprechungChannelTypes[BesprechungChannelTypes.mediathek]:
          window.open(location.origin + '/exit?url=' + mediaurl, "_blank");
          break;
        case BesprechungChannelTypes[BesprechungChannelTypes.streaming]:
          window.open(location.origin + '/exit?url=' + this.media[0].mediaurl, "_blank");
          this.close();
          break;
      }
    } else if (this.data.mode === MediaDialogMode.youtube) {
      if (this.data.url) {
        window.open(location.origin + '/exit?url=' + this.data.url, "_blank");
        this.close();
      }
    }

  }

  getShowsOnDay(airdate: any): any[] {
    return this.media.filter(value => new Date(parseDate(value.airdate)).getDate().valueOf() === airdate.getDate().valueOf());
  }

  getStartTimeOnAirdate(s: Medien): any {
    return format(parseDate(s.airdate), 'HH:mm');
  }

  getEndTimeOnAirdate(s: any): any {
    const air = parseDate(s.airdate);
    return format(air.setSeconds(air.getSeconds() + s.runtime), 'HH:mm');
  }

  getDistinctResources(): any[] {
    return this.media.map(value => {
      return { link: value.mediaurl, title: value.episodetitle, enddate: value.enddate };
    })
      .filter((value1, index, self) => self.findIndex(value => value.link === value1.link) === index);
  }

  close(): void {
    this.dialogRef.close();
  }

  actionsVisible(): boolean {
    return this.data.sourceType !== BesprechungChannelTypes[BesprechungChannelTypes.tv]
      && this.data.sourceType !== BesprechungChannelTypes[BesprechungChannelTypes.mediathek]
      && (this.data.sourceType === BesprechungChannelTypes[BesprechungChannelTypes.streaming] && this.goodRating());
  }

  dayFormat(date: Date): string {
    return format(date, 'd.M.');
  }

  goodRating(): boolean {
    return this.data.rating > 2;
  }
}
